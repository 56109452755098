@import url('https://pro.fontawesome.com/releases/v5.10.0/css/all.css');

.center-container {
	display: flex;
	width: 100%;
	height: 100vh;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #ffc800;

	.error-h3 {
		margin-top: 1rem;
		margin-bottom: 0;
	}
	.erorr-h5 {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 100%;
}
.auth-wrapper {
	width: 17.5rem !important;

	a {
		color: #ffc800;
	}
	.btn {
		min-height: 2.5rem;
	}
}

.btn {
	display: flex !important;
	font-family: 'DB HelvethaicaX', sans-serif !important;
	border: none;
	outline: 0;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.text-center {
	text-align: center;
}
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mt-3 {
	margin-top: 3rem;
}
.mt-4 {
	margin-top: 4rem;
}
.mt-5 {
	margin-top: 5rem;
}
.mb-1 {
	margin-top: 1rem;
}
.mb-2 {
	margin-top: 2rem;
}
.mb-3 {
	margin-top: 3rem;
}
.pb-3 {
	padding-bottom: 3rem;
}

.d-block {
	display: block;
}
.d-none {
	display: none;
}

.sidebar {
	padding: 64px 48px !important;
}
.sidebar__logo {
	display: block;
	margin: auto;
	width: 100px;
}

.promo__home {
	display: flex;
	flex-direction: column;

	.btn {
		font-size: 1rem !important;
		margin-top: 0.75rem;
		margin-left: 0 !important;
		max-width: 50%;
		min-height: 2.5rem;
	}
}

.btn-submit .btn {
	min-height: 2.5rem;
}

.bank-image {
	padding: 12px;
	border-radius: 0.5rem;
	width: 80px;
	margin-bottom: 0.5rem;

	img {
		width: 80px !important;
		margin-bottom: 0rem !important;
	}
}

.promo__info {
	.radio-group {
		width: 100% !important;
		margin-bottom: 0.5rem;
	}
	button {
		margin-left: 0 !important;
	}
}
.captcha-row {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;

	span {
		margin-left: 1rem;
	}
	img {
		border: 4px solid #ffc800;
		border-radius: 0.25rem;
	}
}

.tab__link {
	font-size: 0.75rem !important;
}

.btn-play {
	font-size: 1rem;
}

.success-section {
	background-color: #fff;
	color: #000;
	margin-bottom: 1rem;
	padding: 2rem;
	border-radius: 20px;
	text-align: center;

	h4 {
		font-size: 1.5rem;
		margin: 0;
	}
	h6 {
		margin: 0;
	}
	h5 {
		margin: 0;

		&:first-child {
			margin-top: 0.75rem;
		}
	}
	.btn {
		display: block;
		margin-top: 1.5rem;
		min-height: 0rem;
	}
}

.wrapper--cashback {
	padding: 0 15px;
	padding-top: 1rem !important;

	button {
		font-size: 1rem;
		min-width: 15rem;
	}
}

.Toastify__toast {
	font-family: 'DB HelvethaicaX';
	padding: 0.75rem 1rem;
	border-radius: 0.1rem;

	.Toastify__toast-body {
		font-size: 0.85rem;
	}
}
.btn--logout {
	margin-top: 1rem !important;
	width: 5rem;
}

.upload-slip-wrapper {
	display: block;
	width: 100%;

	input[type='file'] {
		display: none;
	}
	button {
		min-height: 2.5rem;
		margin-left: 0.5rem;
		font-size: 1rem;
	}
}

.dialog {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	display: none;
	z-index: 1200;
	animation: fadeIn 250ms 1;

	.dialog__wrapper {
		position: absolute;
		min-width: 400px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #101010;
		border-radius: 20px;
		padding: 1.5rem 1rem 1rem;

		h1 {
			font-size: 1.5rem;
			text-align: center;
		}
		h6 {
			font-size: 0.7rem;
			text-align: center;
			margin: 0;
		}
	}
	.btn {
		border: 0;
	}
}
.ambpay-qrcode {
	text-align: center;
	margin-top: 1rem;

	img {
		width: 150px;
		border: 2px solid #eee;
	}
}
.truewallet-qr-dialog-form {
	margin-top: 1rem;
}
.truewallet-qr-deposit {
	margin-top: 1rem;
	text-align: center;

	img {
		width: 150px;
		border: 2px solid #eee;
		border-radius: 0.25rem;
	}
	h5 {
		margin: 0.5rem 0 0 0;
		font-size: 1.2rem;
		color: #ffc800;
	}
}

@media screen and (max-width: 576px) {
	.btn {
		flex-direction: column;
	}
	.auth-wrapper {
		padding: 0 1rem;
	}
	.ambpay__wrapper {
		position: absolute;
		top: auto;
		left: 0;
		right: 0;
		bottom: 0;
		min-width: 100%;
		transform: translate(0, 0);
		background-color: #101010;
		border-radius: 20px 20px 0 0;
		padding: 1.5rem 1rem 1rem;
		box-sizing: border-box;
	}

	.Toastify__toast-container {
		padding: 0.5rem;

		.Toastify__toast {
			margin-bottom: 0.5rem;
		}
	}
}
@media screen and (max-width: 992px) {
	.topbar__member .btn {
		display: none !important;
	}
}
