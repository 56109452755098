@font-face {
	font-family: 'DB HelvethaicaX';
	src: url('./webfonts/DBHelvethaicaX-55Regular.ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'DB HelvethaicaX';
	src: url('./webfonts/DBHelvethaicaX-65Med.ttf');
	font-weight: bold;
	font-style: normal;
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 0, 219, 0.87);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 0, 219, 0.87);
		box-shadow: 0 0 0 0 rgba(255, 0, 219, 0.87);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
		box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
		box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
	}
}
@-webkit-keyframes pulseWheel {
	0% {
		-webkit-box-shadow: 0 0 20px 0 #ffc800;
	}
	70% {
		-webkit-box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
	}
}
@keyframes pulseWheel {
	0% {
		-moz-box-shadow: 0 0 20px 0 #ffc800;
		box-shadow: 0 0 20px 0 #ffc800;
	}
	70% {
		-moz-box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
		box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
	}
	100% {
		-moz-box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
		box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
	}
}
@-webkit-keyframes pulseWheelMobile {
	0% {
		-webkit-box-shadow: 0 0 30px 0 #ffc800;
	}
	70% {
		-webkit-box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
	}
}
@keyframes pulseWheelMobile {
	0% {
		-moz-box-shadow: 0 0 30px 0 #ffc800;
		box-shadow: 0 0 30px 0 #ffc800;
	}
	70% {
		-moz-box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
		box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
	}
	100% {
		-moz-box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
		box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes blinkTo {
	50% {
		opacity: 0;
	}
}
@keyframes blinkForm {
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.btn {
	position: relative;
	padding: 14px 28px;
	text-align: center;
	border-radius: 38px;
	overflow: hidden;
	font-weight: bold;
}
.btn span {
	z-index: 10;
}
.btn span i {
	font-size: 16px;
	margin-left: 8px;
}
.btn--primary {
	color: #000 !important;
	background-color: #ffc800;
}
.btn--primary:hover {
	background-color: rgba(255, 200, 0, 0.75);
}
.btn--danger {
	color: #fff !important;
	background-color: #e5003c;
}
.btn--danger:hover {
	background-color: rgba(229, 0, 60, 0.75);
}
.btn--secondary {
	color: #fff !important;
	background-image: linear-gradient(249deg, #ff00db 0%, #8726ff 100%);
}
.btn--secondary:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0;
	transition: 250ms all ease-out;
}
.btn--secondary:hover:after {
	opacity: 0.5;
}
.btn--dark {
	color: #fff !important;
	background-color: black;
}
.btn--dark:hover {
	background-color: rgba(0, 0, 0, 0.75);
}

.radio-group {
	display: block;
	position: relative;
	padding: 16px 0 16px 64px;
	margin-right: 24px;
	border-radius: 38px;
	cursor: pointer;
	font-size: 24px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #0f0f0f;
}
.radio-group:before {
	content: '';
	position: relative;
	width: 24px;
}
.radio-group input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.radio-group input:checked ~ .checkmark {
	color: #00be7b;
	background-color: #00be7b;
}
.radio-group input:checked ~ .checkmark:after {
	opacity: 1;
}
.radio-group input:checked ~ .text {
	color: #00be7b;
}
.radio-group .checkmark {
	position: absolute;
	top: 16px;
	left: 24px;
	height: 24px;
	width: 24px;
	background-color: #eee;
	border-radius: 50%;
	transition: 250ms all ease-out;
}
.radio-group .checkmark:before,
.radio-group .checkmark:after {
	content: '';
	position: absolute;
	height: 5px;
	width: 9px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transform: translate(-50%, -75%) rotate(-45deg);
	left: 50%;
	top: 50%;
	opacity: 0;
	transition: 250ms all ease-out;
}
.radio-group:hover .checkmark {
	background-color: #00be7b;
}
.radio-group:hover .checkmark:before {
	opacity: 1;
}

.non-bonus-auto {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 24px;
	position: relative;
}
.non-bonus-auto:before {
	content: '';
	position: absolute;
	left: 15px;
	right: 15px;
	top: -1px;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.1);
}
.non-bonus-auto .radio-group {
	margin: 0;
}

.history {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	padding: 32px 48px;
	display: flex;
	background-color: rgba(255, 255, 255, 0.04);
	border-radius: 20px;
	margin-bottom: 24px;
}
.history h1 {
	color: #ffc800;
	font-size: 2rem;
	line-height: 1;
}
.history p {
	font-size: 1rem;
}
.history small {
	line-height: 0.75;
	display: block;
	font-size: 0.9rem;
}
.history__type {
	align-self: center;
}
.history__amount {
	margin-left: auto;
	text-align: right;
}
.history__amount--status {
	font-size: 0.9rem;
	border-radius: 16px;
	display: inline-block;
}
.history__amount--status span strong {
	color: #ffc800;
}
.history__amount--status.wait {
	padding: 2px 8px;
	color: #000;
	background-color: #ffc800;
}
.history__amount--status.failed {
	padding: 2px 8px;
	color: #fff;
	background-color: #e5003c;
}
.history__amount--status.success {
	padding: 2px 8px;
	color: #fff;
	background-color: #00be7b;
}
@media screen and (max-width: 1200px) {
	.history {
		padding: 16px 24px;
	}
}
@media screen and (max-width: 576px) {
	.history h1 {
		font-size: 1.5rem;
	}
	.history p {
		font-size: 0.9rem;
	}
	.history__amount--status {
		font-size: 0.75rem;
	}
}

@media screen and (max-width: 576px) {
	#bonus .history {
		display: block;
	}
	#bonus .history__amount {
		margin-left: 0;
		text-align: left;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
		margin-top: 0.5rem;
		padding-top: 0.5rem;
	}
}

.download {
	-webkit-box-flex: 0;
	max-width: 50%;
	flex: 0 0 50%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	display: block;
	margin-bottom: 32px;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
	.download {
		-webkit-box-flex: 0;
		max-width: 100%;
		flex: 0 0 100%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media screen and (max-width: 576px) {
	.download {
		-webkit-box-flex: 0;
		max-width: 100%;
		flex: 0 0 100%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.download__img {
	display: block;
	margin-right: 1rem;
}
.download__info h1 {
	font-size: 1.5rem;
}
.download__info p {
	font-size: 0.9rem;
	opacity: 0.75;
}
.download__info a {
	display: inline-block;
	margin-top: 1rem;
}
.download .title-bar {
	padding: 0;
}

.tab {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	position: relative;
	margin-bottom: 32px;
}
.tab:before {
	content: '';
	position: absolute;
	top: 0;
	left: 15px;
	right: 15px;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.04);
	z-index: 0;
	border-radius: 20px;
}
.tab__link {
	padding: 12px;
	cursor: pointer;
	z-index: 1;
	transition: 250ms all ease-out;
	width: calc(100% / 8);
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
}
.tab__link:first-child {
	border-radius: 20px 0 0 20px;
}
.tab__link:last-child {
	border-radius: 0 20px 20px 0;
}
.tab__link.tab-active {
	color: #000;
	background-color: #ffc800;
}
.tab__link:hover:not(.tab-active) {
	background-color: rgba(255, 255, 255, 0.04);
}
@media screen and (max-width: 1200px) {
	.tab__link {
		padding: 12px 0;
		width: calc(100% / 4);
		text-align: center;
		font-size: 0.9rem;
	}
	.tab__link:last-child {
		border-radius: 0 20px 20px 0;
	}
}
.tab__content {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
@media screen and (max-width: 768px) {
	.tab__content {
		padding: 0;
	}
}

.profile {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 32px;
	margin-bottom: 32px;
	position: relative;
}
.profile:before {
	content: '';
	position: absolute;
	left: 15px;
	right: 15px;
	bottom: -1px;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.1);
}
.profile__col {
	width: 40%;
}
.profile__col--account {
	width: 20%;
}
.profile__col--account .bank-info {
	margin: 0;
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	padding: 0;
}
.profile__col--account .bank-info h1 {
	color: #ffc800;
}
.profile__col--withdraw {
	width: 255px;
}
.profile__col--form {
	width: 100%;
	margin-top: 24px;
}
.profile__col--form form {
	width: 510px;
}
.profile__col--form form.affiliate-withdraw {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.profile__col--form form.affiliate-withdraw fieldset {
	width: calc(50% - 24px);
	margin-right: 24px;
}
.profile__col--form form .btn-submit {
	width: 100%;
}
.profile__col--form form button[type='submit'] {
	width: auto;
	min-width: 200px;
}
.profile__col--image {
	width: 20%;
}
.profile__col--image img {
	display: block;
	width: 100px;
	border-radius: 50%;
}
.profile__col--image img.bank {
	border-radius: 20px;
}
.profile__col--image img.qrcode {
	width: 150px;
	border-radius: 20px;
}
.profile__col h2 {
	font-size: 0.9rem;
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 0.5rem;
}
.profile__col p.highlight {
	color: #ffc800;
	font-size: 1.5rem;
}
.profile__col p.danger {
	color: #e5003c;
	font-size: 1.5rem;
}
.profile__col p.link {
	display: block;
}
.profile__col p.link a {
	display: block;
	width: 100px;
}
.profile__col p.link a:hover {
	opacity: 0.75;
}
.profile__col p.link span {
	display: inline-block;
	margin-left: 0;
	margin-top: 0.5rem;
}
.profile__col p i {
	width: 20px;
	font-size: 0.6rem;
	opacity: 0.75;
	margin-right: 0.25rem;
}
.profile__col p .btn-copy {
	margin-left: 1rem;
}
@media screen and (max-width: 1200px) {
	.profile__col {
		width: 35%;
	}
	.profile__col--withdraw {
		width: 200px;
	}
	.profile__col--image {
		width: 30%;
	}
}
@media screen and (max-width: 576px) {
	.profile__col {
		width: 70%;
		margin-bottom: 24px;
	}
	.profile__col--withdraw {
		width: 50%;
	}
	.profile__col--withdraw.xs-right {
		text-align: right;
	}
	.profile__col--form {
		margin-top: 0;
	}
	.profile__col--form form {
		width: 100%;
	}
	.profile__col--form form button[type='submit'] {
		width: 100%;
		min-width: 100%;
	}
	.profile__col--form form.affiliate-withdraw {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.profile__col--form form.affiliate-withdraw fieldset {
		width: 100%;
		margin-right: 0;
	}
	.profile__col--image {
		width: 30%;
		margin-bottom: 24px;
	}
	.profile__col--image img {
		width: 100%;
		margin-left: auto;
	}
	.profile__col--image img.qrcode {
		width: 100%;
		border-radius: 20px;
	}
	.profile__col--order-xs-1 {
		order: 1;
	}
	.profile__col--order-xs-2 {
		order: 2;
	}
	.profile__col--order-xs-3 {
		order: 3;
		width: 100% !important;
		margin: 0;
	}
	.profile__col--order-xs-3 p {
		display: flex;
	}
	.profile__col--order-xs-3 p i {
		width: 20px;
		align-self: center;
	}
	.profile__col--order-xs-3 p .btn-copy {
		margin-left: auto;
	}
}
.profile .title-bar {
	width: 100%;
	padding: 0;
}
.profile .btn {
	cursor: pointer;
	display: inline-block;
	margin-top: 0.5rem;
	transition: 250ms all ease-out;
}

.btn-copy {
	color: #ffc800;
	cursor: pointer;
	position: relative;
	font-size: 0.9rem;
}
.btn-copy i {
	font-size: 0.6rem;
	opacity: 0.75;
	margin-right: 0.25rem;
}
.btn-copy:before {
	content: '';
	position: absolute;
	top: -2px;
	left: -10px;
	right: -10px;
	bottom: -2px;
	border-radius: 20px;
	z-index: 0;
	background-color: rgba(255, 255, 255, 0);
	transition: 250ms all ease-out;
}
.btn-copy:hover:before {
	background-color: rgba(255, 255, 255, 0.1);
}

.change-password {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	display: none;
	z-index: 1200;
	animation: fadeIn 250ms 1;
}
.change-password h1 {
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 0.5rem;
}
.change-password.fadeOut {
	animation: fadeOut 250ms 1;
}
.change-password__wrapper {
	position: absolute;
	min-width: 400px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #101010;
	border-radius: 20px;
	padding: 1.5rem 1rem 1rem;
}
@media screen and (max-width: 576px) {
	.change-password__wrapper {
		position: absolute;
		top: auto;
		left: 0;
		right: 0;
		bottom: 0;
		min-width: 100%;
		transform: translate(0, 0);
		background-color: #101010;
		border-radius: 20px 20px 0 0;
		padding: 1.5rem 1rem 1rem;
		box-sizing: border-box;
	}
}
.change-password .btn {
	border: 0;
}

.msn-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	display: none;
	z-index: 1200;
	animation: fadeIn 250ms 1;
}
.msn-modal--success {
	width: 100%;
	margin: 1rem auto;
	text-align: center;
}
.msn-modal--success i {
	color: #00be7b;
	font-size: 100px;
	margin-bottom: 0.5rem;
}
.msn-modal--failed {
	width: 100%;
	margin: 1rem auto;
	text-align: center;
}
.msn-modal--failed i {
	color: #e5003c;
	font-size: 100px;
	margin-bottom: 0.5rem;
}
.msn-modal .submit-redeem {
	display: block;
}
.msn-modal h1 {
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 0.5rem;
}
.msn-modal.fadeOut {
	animation: fadeOut 250ms 1;
}
.msn-modal__wrapper {
	position: absolute;
	min-width: 400px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #101010;
	border-radius: 20px;
	padding: 1.5rem 1rem 1rem;
}
@media screen and (max-width: 576px) {
	.msn-modal__wrapper {
		position: absolute;
		top: auto;
		left: 0;
		right: 0;
		bottom: 0;
		min-width: 100%;
		transform: translate(0, 0);
		background-color: #101010;
		border-radius: 20px 20px 0 0;
		padding: 1.5rem 1rem 1rem;
		box-sizing: border-box;
	}
}
.msn-modal .btn {
	border: 0;
}

.affiliate-wrapper {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
.affiliate-wrapper .title-bar {
	width: 100%;
	padding: 0;
}
.affiliate-wrapper__list {
	display: flex;
	border-radius: 20px;
	padding: 24px 32px;
	background-color: rgba(255, 255, 255, 0.04);
	margin-bottom: 24px;
	width: 100%;
	box-sizing: border-box;
}
.affiliate-wrapper__list img {
	border-radius: 50%;
	margin-right: 24px;
	width: 72px;
	align-items: start;
}
.affiliate-wrapper__list--info {
	margin: auto 0;
	width: 100%;
	display: flex;
}
.affiliate-wrapper__list--info .profile-info h1 {
	font-size: 1rem;
}
.affiliate-wrapper__list--info .profile-info p {
	font-size: 0.9rem;
	color: rgba(255, 255, 255, 0.75);
}
.affiliate-wrapper__list--info .status {
	color: #fff;
	margin-left: auto;
	display: flex;
}
.affiliate-wrapper__list--info .status .success {
	background-color: #00be7b;
	padding: 8px 16px;
	border-radius: 20px;
	margin: auto 0;
}
.affiliate-wrapper__list--info .status .failed {
	background-color: #e5003c;
	padding: 8px 16px;
	border-radius: 20px;
	margin: auto 0;
}
@media screen and (max-width: 576px) {
	.affiliate-wrapper__list img {
		max-height: 72px;
	}
	.affiliate-wrapper__list--info {
		display: block;
	}
	.affiliate-wrapper__list--info .status {
		font-size: 0.75rem;
		margin-top: 0.5rem;
	}
}

.notice {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
.notice__list {
	background-color: rgba(255, 255, 255, 0.04);
	padding: 16px 24px;
	border-radius: 20px;
	margin-bottom: 0.75rem;
}
.notice__list p.alert {
	display: flex;
}
.notice__list p.alert strong,
.notice__list p.alert i {
	color: #e5003c;
}
.notice__list p.warning {
	display: flex;
}
.notice__list p.warning strong,
.notice__list p.warning i {
	color: #ffc800;
}
.notice__list p i {
	font-size: 0.75rem;
	width: 20px;
	margin-right: 0.5rem;
	align-self: center;
}
@media screen and (max-width: 576px) {
	.notice__list {
		font-size: 0.75rem;
	}
}

.bank-info {
	-webkit-box-flex: 0;
	max-width: 16.6666666667%;
	flex: 0 0 16.6666666667%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 32px;
}
.bank-info img {
	width: 100%;
	display: block;
	border-radius: 20px;
	margin-bottom: 1rem;
}
.bank-info h1 {
	line-height: 1;
	font-size: 1.5rem;
}
.bank-info small,
.bank-info p {
	opacity: 0.75;
}
.bank-info .btn-copy {
	display: inline-block;
	margin-top: 12px;
}
@media screen and (max-width: 1600px) {
	.bank-info {
		-webkit-box-flex: 0;
		max-width: 25%;
		flex: 0 0 25%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media screen and (max-width: 1200px) {
	.bank-info {
		-webkit-box-flex: 0;
		max-width: 33.3333333333%;
		flex: 0 0 33.3333333333%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
	.bank-info small {
		font-size: 0.75rem;
		line-height: 1;
		display: block;
		margin-bottom: 0.25rem;
	}
	.bank-info p {
		font-size: 0.85rem;
	}
	.bank-info h1 {
		font-size: 1rem;
	}
}
@media screen and (max-width: 576px) {
	.bank-info {
		-webkit-box-flex: 0;
		max-width: 50%;
		flex: 0 0 50%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.lucky-wheel {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	width: 100%;
	margin-top: 48px;
	margin-bottom: 84px;
}
.lucky-wheel:before {
	content: '';
	position: absolute;
	top: 47%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #000;
	width: 500px;
	height: 500px;
	border-radius: 50%;
	z-index: 0;
	box-shadow: 0 0 200px 10px #ffc800;
}
@media screen and (max-width: 1200px) {
	.lucky-wheel:before {
		box-shadow: 0 0 120px 10px #ffc800;
	}
}
@media screen and (max-width: 576px) {
	.lucky-wheel:before {
		top: 50%;
		width: 260px;
		height: 260px;
		box-shadow: 0 0 120px 0 #ffc800;
	}
}
.lucky-wheel__frame {
	position: absolute;
	top: -91px;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	z-index: 0;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__frame {
		top: -49px;
		width: 100%;
	}
	.lucky-wheel__frame img {
		width: 100% !important;
		top: -1px !important;
		left: 0 !important;
	}
}
.lucky-wheel__frame img {
	display: flex;
	justify-content: center;
}
.lucky-wheel__frame .animate-form {
	animation: blinkForm 0.5s step-start 0s infinite;
}
.lucky-wheel__frame .animate-to {
	animation: blinkTo 0.5s step-start 0s infinite;
	position: absolute;
	top: 0;
	left: 0;
}
.lucky-wheel__wheel {
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: center;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__wheel {
		width: 100%;
	}
	.lucky-wheel__wheel canvas {
		width: 90% !important;
		height: 90% !important;
	}
}
.lucky-wheel__arrow {
	position: absolute;
	top: -72px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 20;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__arrow {
		top: -40px;
		width: 36px;
	}
	.lucky-wheel__arrow img {
		width: 100%;
	}
}
.lucky-wheel__logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 20;
}
.lucky-wheel__logo:before {
	content: '';
	position: absolute;
	top: 47%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #000;
	z-index: -1;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	animation: pulseWheel 1s infinite;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__logo:before {
		animation: pulseWheelMobile 1s infinite;
	}
}
@media screen and (max-width: 576px) {
	.lucky-wheel__logo--bg {
		width: 100px;
	}
}
.lucky-wheel__logo .logo-wheel {
	position: absolute;
	top: 48%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90px;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__logo .logo-wheel {
		max-width: 72px;
	}
}
.lucky-wheel__play {
	position: absolute;
	bottom: -84px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 20;
}
@media screen and (max-width: 576px) {
	.lucky-wheel__play {
		max-width: 400px;
		bottom: -70px;
	}
	.lucky-wheel__play img {
		width: 100%;
	}
}
.lucky-wheel__play #spin_button {
	cursor: pointer;
	transition: 250ms all ease-out;
}
.lucky-wheel__play #spin_button.spin-disabled {
	cursor: not-allowed;
}
.lucky-wheel__play #spin_button:hover {
	transform: scale(1.05);
}
.lucky-wheel__play a {
	display: block;
}

.num-spin {
	width: 100%;
	text-align: center;
}

.reward-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	display: none;
	z-index: 1200;
	text-align: center;
	animation: fadeIn 250ms 1;
}
.reward-modal h1 {
	font-size: 3rem;
	text-align: center;
	line-height: 1;
	color: #ffc800;
	margin-bottom: 1rem;
}
.reward-modal p {
	text-align: center;
}
.reward-modal.fadeOut {
	animation: fadeOut 250ms 1;
}
.reward-modal__wrapper {
	position: absolute;
	min-width: 400px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #101010;
	border-radius: 20px;
	padding: 1.5rem 1rem 1rem;
}
@media screen and (max-width: 576px) {
	.reward-modal__wrapper {
		min-width: calc(100% - 48px);
		box-sizing: border-box;
	}
}
.reward-modal .btn {
	display: inline-block;
	min-width: 200px;
	cursor: pointer;
	transition: 250ms all ease-out;
}

.reward-list {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	padding: 16px 24px;
	background-color: rgba(255, 255, 255, 0.04);
	border-radius: 20px;
	margin-bottom: 24px;
}
.reward-list h1 {
	font-size: 1.25rem;
	color: #ffc800;
}
.reward-list p {
	font-size: 0.9rem;
	color: rgba(255, 255, 255, 0.75);
}
@media screen and (max-width: 576px) {
	.reward-list h1 {
		font-size: 1rem;
	}
	.reward-list p {
		font-size: 0.8rem;
	}
}
.reward-list__amount {
	margin-left: auto;
}

.slot-logo {
	text-align: center;
	display: flex;
	padding: 0 15px;
}
.slot-logo__img {
	width: calc(100% / 4);
	padding: 0 12px;
}
.slot-logo__img img {
	height: 72px;
}
@media screen and (max-width: 992px) {
	.slot-logo__img {
		min-width: calc(100% / 8);
		padding: 0;
	}
	.slot-logo__img img {
		height: 30px;
	}
}
@media screen and (max-width: 576px) {
	.slot-logo__img {
		min-width: calc((100% / 4) - 8px);
	}
}

.slot-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.slot-card {
	width: calc((100% / 8) - 24px);
	padding: 20px 12px;
	text-align: center;
	position: relative;
	display: block;
	animation: fadeIn 0.25s linear;
	position: relative;
}
.slot-card:hover .slot-card__img {
	transform: scale(1.05);
	border: 1px solid #ffc800;
}
.slot-card:hover .slot-card__new {
	transform: scale(1.18);
}
.slot-card__img {
	width: 100%;
	display: block;
	transition: all 250ms ease-in-out;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.15);
}
.slot-card__new {
	position: absolute;
	top: 12px;
	left: 0;
	width: 72px;
	transition: all 250ms ease-in-out;
}
.slot-card span {
	display: block;
	margin-top: 0.5rem;
	font-size: 20px;
}
@media screen and (max-width: 992px) {
	.slot-card {
		width: calc((100% / 6) - 24px);
	}
	.slot-card__new {
		width: 48px;
	}
}
@media screen and (max-width: 576px) {
	.slot-card {
		width: calc((100% / 2) - 24px);
	}
}

.custom-tab-slot.tab .tab__link {
	width: calc(100% / 4);
	font-size: 24px;
}
@media screen and (max-width: 992px) {
	.custom-tab-slot {
		width: 100%;
	}
	.custom-tab-slot.tab .tab__link {
		width: calc(100% / 8);
		font-size: 18px;
	}
}
@media screen and (max-width: 576px) {
	.custom-tab-slot {
		border-radius: 0;
		padding-right: 0;
	}
	.custom-tab-slot.tab:before {
		display: none;
	}
	.custom-tab-slot.tab .tab__link {
		min-width: calc(100% / 4);
	}
	.custom-tab-slot.tab .tab__link:last-child {
		border-radius: 0 20px 20px 0;
	}
}

.super-tab {
	width: 100%;
	position: relative;
}
@media screen and (max-width: 576px) {
	.super-tab {
		overflow-x: scroll;
		width: 100%;
		margin-left: -9px;
		margin-right: -35px;
	}
	.super-tab:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 32px;
		height: 41px;
		background: rgba(255, 255, 255, 0.04);
		border-radius: 20px;
	}
}

.casino-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 3rem;
	margin-top: 0.1rem;
	padding-left: 15px;
	padding-right: 15px;
}
@media screen and (max-width: 992px) {
	.casino-wrapper {
		margin-bottom: 1rem;
	}
}

.casino-card {
	width: calc((100% / 5) - 1rem);
	padding: 0.5rem;
	display: block;
	position: relative;
	margin-bottom: 2.5rem;
}
@media screen and (max-width: 992px) {
	.casino-card {
		width: calc((100% / 5) - 0.5rem);
		padding: 0.25rem;
	}
}
@media screen and (max-width: 576px) {
	.casino-card {
		width: calc((100% / 3) - 0.5rem);
		padding: 0.25rem;
		margin-bottom: 1rem;
	}
}
.casino-card:hover .casino-card__img {
	background-color: #745b00;
}
.casino-card:hover .casino-card__img .women-card {
	transform: translateX(-50%) scale(1.05);
}
.casino-card__img {
	background-color: #333;
	display: block;
	position: relative;
	border-radius: 20px;
	transition: background-color 250ms ease-in-out;
}
.casino-card__img .bg-card {
	padding-top: 75%;
	overflow: hidden;
	position: relative;
	border-radius: 20px;
}
@media screen and (max-width: 992px) {
	.casino-card__img .bg-card {
		border-radius: 12px;
	}
}
.casino-card__img .bg-card img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	display: block;
	filter: grayscale(100%);
	mix-blend-mode: multiply;
}
.casino-card__img .women-card {
	position: absolute;
	left: 50%;
	bottom: 0;
	height: 115%;
	transform: translateX(-50%);
	transition: all 250ms ease;
}
@media screen and (max-width: 992px) {
	.casino-card__img .women-card {
		height: 110px;
	}
}
.casino-card__logo {
	position: absolute;
	left: 50%;
	bottom: -10%;
	transform: translateX(-50%);
	transition: transform 1s;
}
.casino-card__logo img {
	height: 80px;
}
.casino-card__logo:before {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 1px;
	height: 1px;
	border-radius: 50%;
	box-shadow: 0 0 30px 40px rgba(0, 0, 0, 0.75);
	z-index: -1;
}
@media screen and (max-width: 992px) {
	.casino-card__logo img {
		height: 40px;
	}
}

.topup-method {
	display: flex;
	flex-wrap: wrap;
	width: 840px;
	margin: 0 auto;
}
.topup-method > p {
	width: 100%;
	margin-bottom: 20px;
	text-align: center;
}

.topup-card {
	width: calc(50% - 40px);
	height: 124px;
	margin: 20px;
	background-color: #ffc800;
	border-radius: 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.topup-card__wrapper {
	padding: 32px;
	display: flex;
	align-items: center;
	color: #000;
}
.topup-card img {
	width: 60px;
	margin-right: 32px;
}
.topup-card i {
	margin-right: 32px;
}
.topup-card span {
	font-size: 32px;
}
.topup-card:hover {
	transform: scale(1.05);
	box-shadow: 0 10px 28px rgba(255, 200, 0, 0.3);
}
@media screen and (max-width: 767px) {
	.topup-card {
		width: 100%;
		height: 80px;
		margin: 12px;
	}
	.topup-card__wrapper {
		padding: 16px;
	}
	.topup-card img {
		width: 48px;
		margin-right: 32px;
	}
	.topup-card i {
		margin-right: 32px;
	}
	.topup-card span {
		font-size: 28px;
	}
}

.topup-decimal {
	padding-left: 15px;
	padding-right: 15px;
	width: 840px;
}
.topup-decimal #inputOtherTopup {
	max-width: 50%;
}
.topup-decimal--has-image {
	display: flex;
	width: 100%;
	margin: 48px auto 24px;
}
.topup-decimal__title {
	display: flex;
}
.topup-decimal__title--img {
	padding-top: 0.5rem;
	padding-right: 0.75rem;
}
.topup-decimal__title--img img {
	width: 32px;
	display: block;
}
.topup-decimal__title--text h2 {
	margin-top: 0;
}
.topup-decimal__cancel {
	margin-left: auto;
}
.topup-decimal__cancel a {
	color: #fff !important;
	display: block;
	padding: 15px 32px;
	text-align: center;
	background: #e5003c;
	border-radius: 12px;
	line-height: 1;
}
.topup-decimal__cancel a img {
	margin-right: 0.5rem;
}
.topup-decimal__cancel a:hover {
	opacity: 0.75;
}
.topup-decimal .btn--primary {
	max-width: 200px;
	margin-top: 40px;
	cursor: pointer;
}
@media only screen and (max-width: 576px) {
	.topup-decimal {
		width: 100%;
	}
	.topup-decimal #inputOtherTopup {
		max-width: 100%;
	}
	.topup-decimal--has-image {
		display: block;
		margin-top: 0;
	}
	.topup-decimal__title--text {
		margin-bottom: 1rem;
	}
	.topup-decimal__title--text h2 {
		font-size: 0.9rem;
	}
	.topup-decimal__title--text p {
		font-size: 0.7rem;
	}
	.topup-decimal .btn--primary {
		max-width: 100%;
	}
}

.form-topup {
	display: flex;
	flex-wrap: wrap;
	margin: 48px -15px 0 -15px;
	width: 100%;
}
@media only screen and (max-width: 576px) {
	.form-topup {
		margin: 32px 0;
	}
}

input[type='radio'] {
	visibility: hidden;
	height: 0;
	width: 0;
	position: absolute;
}

.btn-radio {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	margin-bottom: 30px;
	box-sizing: border-box;
}
.btn-radio span {
	display: flex;
	justify-content: center;
	align-content: center;
	text-align: center;
	cursor: pointer;
	color: white;
	font-size: 40px;
	font-weight: bold;
	border-radius: 12px;
	border: 1px solid rgba(255, 255, 255, 0.25);
	transition: 250ms all ease-out;
	min-height: 60px;
	line-height: 60px;
}
.btn-radio:last-child span {
	font-size: 32px;
	font-weight: normal;
}
.btn-radio:hover span {
	background-color: #ffc800;
	color: #1c1817;
	border: 1px solid transparent;
}
@media only screen and (max-width: 576px) {
	.btn-radio {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		margin-bottom: 24px;
	}
	.btn-radio span {
		font-size: 36;
		min-height: 54px;
		line-height: 54px;
	}
	.btn-radio:nth-of-type(2n-1) {
		padding-left: 0;
		padding-right: 7.5px;
	}
	.btn-radio:nth-of-type(2n) {
		padding-left: 7.5px;
		padding-right: 0;
	}
}

input[type='radio']:checked + .btn-radio span {
	background-color: #ffc800;
	color: #1c1817;
	border: 1px solid transparent;
}

.topup-body {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
}

.topup-info {
	width: calc(50% - 7.5px);
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 12px;
	text-align: center;
	padding: 28px 0;
	margin-bottom: 24px;
}
.topup-info:nth-of-type(2n-1) {
	margin-right: 7.5px;
}
.topup-info:nth-of-type(2n) {
	margin-left: 7.5px;
}
.topup-info h1 {
	font-size: 100px;
	margin: 0;
	line-height: 0.8;
}
.topup-info h1 span {
	color: #ffc800;
}
.topup-info p {
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.topup-info {
		width: 100%;
	}
	.topup-info h1 {
		font-size: 72px;
		line-height: 1;
	}
}

.caution {
	width: 100%;
	margin-bottom: 40px;
}
.caution--warning {
	color: #e5003c;
}
.caution__item {
	display: flex;
}
.caution__item p {
	opacity: 1;
}
.caution__item span,
.caution__item img {
	display: block;
	min-width: 20px;
	text-align: center;
	margin-right: 1rem;
}
.caution__item img {
	align-self: start;
	margin-top: 0.25rem;
}
@media only screen and (max-width: 767px) {
	.caution__item p {
		font-size: 0.75rem;
	}
}

.topup-transaction {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	width: 1024px;
}
@media only screen and (max-width: 1024px) {
	.topup-transaction {
		width: 100%;
	}
}
@media only screen and (max-width: 767px) {
	.topup-transaction {
		padding: 0 15px;
	}
}

.topup-footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.topup-footer .bank-info {
	-webkit-box-flex: 0;
	max-width: 20%;
	flex: 0 0 20%;
}
@media only screen and (max-width: 767px) {
	.topup-footer {
		justify-content: start;
	}
	.topup-footer .bank-info {
		-webkit-box-flex: 0;
		max-width: 50%;
		flex: 0 0 50%;
	}
	.topup-footer .bank-info:nth-of-type(2n-1) {
		padding-left: 0;
	}
	.topup-footer .bank-info:nth-of-type(2n) {
		padding-right: 0;
	}
}

.result {
	margin: 0 auto;
	text-align: center;
	margin-top: 2rem;
}
.result img {
	margin-bottom: 0.5rem;
}
.result h1 {
	margin-top: 0;
}

.upload-slip {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 0 15px;
}
.upload-slip label {
	display: inline-block;
	margin-top: 1rem;
	cursor: pointer;
	width: 180px;
}
.upload-slip span {
	display: block;
	font-size: 0.9rem;
	color: #e5003c;
}
@media only screen and (max-width: 767px) {
	.upload-slip label {
		width: auto;
		display: block;
	}
	.upload-slip span {
		font-size: 0.75rem;
		margin-top: 16px;
		text-align: center;
	}
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0 0 0.5rem 0;
	border: 0;
}
fieldset:focus {
	outline: 0 !important;
}
fieldset .select-group {
	position: relative;
}
fieldset .select-group i {
	position: absolute;
	height: 1em;
	font-size: 0.625em;
	line-height: 1;
	right: 0.75rem;
	top: 50%;
	margin-top: -0.5em;
}
fieldset select {
	position: relative;
}
fieldset select:after {
	content: '/';
	position: absolute;
	right: 0;
}
fieldset p {
	font-size: 0.9rem;
	text-align: left;
	padding-bottom: 0.1rem;
	padding-left: 1.6rem;
}
fieldset div:focus,
fieldset label:focus {
	outline: 0 !important;
}
fieldset label {
	font-size: 0.9rem;
	opacity: 0.75;
	display: block;
	margin-bottom: 0.25rem;
}
fieldset .form-control,
fieldset select,
fieldset option {
	font-family: DB HelvethaicaX, Arial, sans-serif;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fbfaf9;
	display: block;
	width: 100%;
	height: 58px;
	padding: 0 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	background-clip: padding-box;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-sizing: border-box;
	margin-bottom: 0.5rem;
}
fieldset .form-control:hover,
fieldset select:hover,
fieldset option:hover {
	background-color: rgba(0, 0, 0, 0.5);
	border-color: #ffc800;
	color: #fbfaf9;
}
fieldset .form-control:focus,
fieldset select:focus,
fieldset option:focus {
	outline: 0 !important;
}
fieldset .form-control select,
fieldset select select,
fieldset option select {
	position: relative;
}
fieldset .form-control select:after,
fieldset select select:after,
fieldset option select:after {
	content: 'v';
	position: absolute;
	top: 0;
	right: 0;
	color: #fff;
}

.button-close {
	position: absolute;
	top: -1rem;
	right: -1rem;
	text-shadow: none;
	font-size: 1.25rem;
	color: rgba(255, 255, 255, 0.75);
	z-index: 1050;
	opacity: 0.8;
	padding: 0.4rem 0.7rem;
	margin: -1rem -1rem -1rem auto;
	cursor: pointer;
	transition: 250ms all ease-out;
}
@media screen and (max-width: 576px) {
	.button-close {
		right: 1rem;
	}
}
.button-close:hover {
	color: white;
}

button[type='submit'] {
	font-family: DB HelvethaicaX, Arial, sans-serif;
	font-size: 24px;
	border: 0;
	display: block;
	width: 100%;
	cursor: pointer;
}
button[type='submit']:hover,
button[type='submit']:focus {
	outline: 0;
}

body,
html {
	color: #fff;
	background-color: #000;
	margin: 0;
	padding: 0;
	font-family: DB HelvethaicaX, Arial, sans-serif;
	font-weight: normal;
	font-size: 24px;
}

a {
	color: #fff;
	text-decoration: none;
	transition: 250ms all ease-out;
}
a:hover {
	color: #ffc800;
}

h1,
h2,
h3,
p {
	margin: 0;
}

#root {
	display: flex;
	position: relative;
	min-height: 100vh;
}

.sidebar {
	width: 280px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.04);
	overflow-x: hidden;
	padding: 96px 48px;
}
@media screen and (max-width: 1200px) {
	.sidebar {
		width: 280px;
	}
}
@media screen and (max-width: 992px) {
	.sidebar {
		display: none;
	}
}
.sidebar__logo {
	display: block;
	width: 150px;
	margin-bottom: 56px;
}
.sidebar__logo img {
	display: block;
	width: 100%;
}
.sidebar ul {
	margin: 0;
	padding: 0;
}
.sidebar ul li {
	list-style: none;
}
.sidebar ul li a {
	display: block;
	font-size: 1rem;
	padding: 15px 0;
	opacity: 0.75;
	position: relative;
}
.sidebar ul li a i {
	width: 24px;
	text-align: center;
	font-size: 0.9rem;
	margin-right: 16px;
	color: #ffc800;
}
.sidebar ul li a.active-menu {
	opacity: 1;
}
.sidebar ul li a.active-menu:before {
	content: '';
	position: absolute;
	top: 0;
	left: -48px;
	right: -48px;
	bottom: 0;
	z-index: -1;
	background-color: black;
}
.sidebar ul li a:hover:not(.active-menu) {
	opacity: 1;
}
.sidebar ul li a:hover:not(.active-menu):before {
	content: '';
	position: absolute;
	top: 0;
	left: -48px;
	right: -48px;
	bottom: 0;
	z-index: -1;
	background-color: rgba(255, 255, 255, 0.04);
}

.main {
	position: relative;
	width: 100%;
	padding: 96px 48px;
}
@media screen and (max-width: 992px) {
	.main {
		padding: 48px;
	}
	.main--space-y {
		margin-top: 60px;
		margin-bottom: 94px;
	}
}
@media screen and (max-width: 576px) {
	.main {
		padding: 24px;
	}
}

.topbar-mobile {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	background-color: #0f0f0f;
	padding-left: 12px;
	padding-right: 12px;
	display: none;
}
@media screen and (max-width: 992px) {
	.topbar-mobile {
		display: flex;
	}
}
.topbar-mobile__back {
	padding: 14px 12px;
	display: flex;
	z-index: 10;
}
.topbar-mobile__back i {
	align-self: center;
}
.topbar-mobile__title {
	padding-top: 14px;
	padding-bottom: 14px;
	width: 100%;
	margin-left: -36px;
	text-align: center;
}
.topbar-mobile__title span {
	line-height: 1;
}

.bottombar-mobile {
	background: #0f0f0f;
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1000;
	display: none;
}
@media screen and (max-width: 992px) {
	.bottombar-mobile {
		display: block;
	}
}
.bottombar-mobile .nav-menu {
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-around;
}
.bottombar-mobile .nav-menu .nav-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	list-style-type: none;
	text-align: center;
}
.bottombar-mobile .nav-menu .nav-item.middle-item span {
	font-size: 0.75rem;
	margin-top: 3px;
	color: rgba(255, 255, 255, 0.75);
	display: block;
}
.bottombar-mobile .nav-menu .nav-item.middle-item .nav-link {
	opacity: 1;
	border-radius: 50%;
	position: relative;
	color: #e4e5ea;
	width: 60px;
	height: 60px;
	margin: -1rem auto auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: -webkit-transform 0.15s ease-out;
	transition: -webkit-transform 0.15s ease-out;
	transition: transform 0.15s ease-out;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	padding: 0;
	background-image: linear-gradient(225deg, #ff00db 0%, #8726ff 100%);
}
.bottombar-mobile .nav-menu .nav-item.middle-item .nav-link i {
	position: relative;
	line-height: 1;
	font-size: 28px;
	color: #fff;
	display: flex;
	align-content: center;
}
.bottombar-mobile .nav-menu .nav-item .nav-link {
	text-decoration: none;
	padding: 0.25rem 0;
	opacity: 0.75;
	color: #fff;
	display: block;
}
.bottombar-mobile .nav-menu .nav-item .nav-link.active {
	opacity: 1;
}
.bottombar-mobile .nav-menu .nav-item .nav-link i {
	display: block;
	line-height: 1.4;
	color: #ffc800;
}
.bottombar-mobile .nav-menu .nav-item .nav-link span {
	font-size: 0.75rem;
	line-height: 1.25;
	display: block;
}

.topbar {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 56px;
}
@media screen and (max-width: 992px) {
	.topbar {
		margin-bottom: 24px;
	}
}
@media screen and (max-width: 992px) {
	.topbar--desktop {
		display: none;
	}
}
.topbar__logo {
	display: none;
	flex-basis: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	max-width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
}
@media screen and (max-width: 992px) {
	.topbar__logo {
		display: block;
	}
	.topbar__logo a {
		display: block;
		max-width: 125px;
	}
	.topbar__logo a img {
		width: 100%;
	}
}
.topbar__welcome {
	display: flex;
	align-items: center;
	-webkit-box-flex: 0;
	max-width: 66.6666666667%;
	flex: 0 0 66.6666666667%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
.topbar__welcome h1 {
	font-size: 2rem;
}
@media screen and (max-width: 1400px) {
	.topbar__welcome {
		-webkit-box-flex: 0;
		max-width: 58.3333333333%;
		flex: 0 0 58.3333333333%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media screen and (max-width: 1200px) {
	.topbar__welcome h1 {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 992px) {
	.topbar__welcome {
		display: none;
	}
}
.topbar__member {
	-webkit-box-flex: 0;
	max-width: 33.3333333333%;
	flex: 0 0 33.3333333333%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	display: flex;
}
.topbar__member a {
	display: flex;
}
.topbar__member .btn {
	margin-left: auto;
	white-space: nowrap;
}
@media screen and (max-width: 1400px) {
	.topbar__member {
		-webkit-box-flex: 0;
		max-width: 41.6666666667%;
		flex: 0 0 41.6666666667%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media screen and (max-width: 1200px) {
	.topbar__member {
		justify-content: flex-end;
	}
	.topbar__member .btn {
		margin-left: 0;
	}
}
@media screen and (max-width: 992px) {
	.topbar__member .btn {
		display: none;
	}
}
.topbar__member--image {
	border-radius: 100%;
	overflow: hidden;
	margin-right: 24px;
	width: 54px;
	height: 54px;
}
.topbar__member--image img {
	display: block;
	height: 100%;
}
@media screen and (max-width: 992px) {
	.topbar__member--image {
		width: 40px;
		height: 40px;
		margin-right: 0;
	}
}
.topbar__member--info h1 {
	font-size: 1rem;
}
.topbar__member--info p {
	font-size: 0.9rem;
	color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 1200px) {
	.topbar__member--info {
		display: none;
	}
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 48px;
}
.wrapper:last-of-type {
	margin-bottom: 0;
}
.wrapper--top {
	margin-bottom: 94px !important;
}
.wrapper--desktop {
	display: flex;
}
@media screen and (max-width: 992px) {
	.wrapper--desktop {
		display: none;
	}
}
.wrapper--mobile {
	display: none;
}
@media screen and (max-width: 992px) {
	.wrapper--mobile {
		display: flex;
	}
}
.wrapper .topbar {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}

.title-bar {
	display: flex;
	margin-bottom: 24px;
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
.title-bar--desktop {
	display: flex;
}
@media screen and (max-width: 1200px) {
	.title-bar--desktop {
		display: none;
	}
}
.title-bar--sub-title {
	display: flex;
}
.title-bar--sub-title h1 {
	font-size: 1rem !important;
}
.title-bar--sub-title h1 i {
	margin-right: 0.5rem;
}
.title-bar--sub-title h1 span {
	margin-left: 0.5rem;
	color: rgba(255, 255, 255, 0.75);
	font-size: 0.9rem;
}
.title-bar--sub-title h1 span strong {
	color: #fff;
}
.title-bar--sub-title h2 {
	font-size: 28px !important;
}
.title-bar--sub-title a {
	display: block;
	margin-left: auto;
}
@media screen and (max-width: 1200px) {
	.title-bar--sub-title h1 span {
		display: block;
		margin: 0;
		font-size: 0.75rem;
	}
}
.title-bar h1 {
	font-size: 1.5rem;
}
.title-bar a {
	margin-left: auto;
	color: #ffc800;
}
.title-bar a:hover {
	opacity: 0.75;
}

.wallet {
	-webkit-box-flex: 0;
	max-width: 66.6666666667%;
	flex: 0 0 66.6666666667%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
@media screen and (max-width: 1200px) {
	.wallet {
		-webkit-box-flex: 0;
		max-width: 100%;
		flex: 0 0 100%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.wallet__main {
	background-color: #ffc800;
	border-radius: 20px;
	padding-left: 48px;
	padding-right: 48px;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	color: #000;
	display: flex;
	position: relative;
}
.wallet__main--amount {
	align-self: center;
	padding-bottom: 12px;
}
.wallet__main--amount h1 {
	font-size: 3.5rem;
	line-height: 0.75;
	font-weight: bold;
}
.wallet__main--amount span {
	opacity: 0.75;
	display: block;
}
.wallet__main--amount span i {
	cursor: pointer;
	font-size: 0.6rem;
	margin-left: 0.25rem;
	position: relative;
}
.wallet__main--amount span i:after {
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	right: -5px;
	bottom: -5px;
	z-index: -1;
	border-radius: 100%;
	background: rgba(255, 255, 255, 0);
	transition: 250ms all ease-out;
}
.wallet__main--amount span i:hover:after {
	background: rgba(255, 255, 255, 0.75);
}
.wallet__main--bonus {
	margin-left: auto;
	padding-bottom: 12px;
	align-self: center;
}
.wallet__main--bonus .btn {
	font-size: 32px;
}
.wallet__main--bonus .btn br {
	display: none;
}
.wallet__main--turnover {
	position: absolute;
	top: 80%;
	border-radius: 20px;
	background-color: #fff;
	padding: 24px 32px;
	color: #000;
	min-width: 360px;
	max-width: 440px;
	display: flex;
}
.wallet__main--turnover span {
	opacity: 0.6;
	align-self: center;
}
.wallet__main--turnover p {
	margin-left: auto;
}
.wallet__main--turnover p strong {
	font-size: 32px;
	font-weight: bold;
}
@media screen and (max-width: 1200px) {
	.wallet__main {
		padding: 32px 48px 48px;
	}
}
@media screen and (max-width: 576px) {
	.wallet__main {
		flex-wrap: wrap;
		padding: 24px 24px 32px;
	}
	.wallet__main--amount {
		width: 100%;
	}
	.wallet__main--amount h1 {
		font-size: 65px;
	}
	.wallet__main--bonus {
		position: absolute;
		top: 80%;
		right: 24px;
		width: calc(40% - 48px);
	}
	.wallet__main--bonus .btn {
		background-color: #232323;
		border-radius: 20px;
		font-size: 20px;
		padding: 16px 0px 10px;
		text-align: center;
	}
	.wallet__main--bonus .btn br {
		display: block;
	}
	.wallet__main--bonus .btn span {
		font-size: 1.5rem;
		line-height: 1;
	}
	.wallet__main--bonus a {
		display: block;
		padding: 24px 32px;
	}
	.wallet__main--turnover {
		width: 75%;
		left: 24px;
		min-width: calc(60% - 48px);
		max-width: calc(60% - 48px);
		display: block;
		padding: 16px;
		font-size: 18px;
	}
	.wallet__main--turnover strong {
		line-height: 1;
	}
}

.affiliate {
	display: flex;
	-webkit-box-flex: 0;
	max-width: 33.3333333333%;
	flex: 0 0 33.3333333333%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
@media screen and (max-width: 1200px) {
	.affiliate {
		display: none;
	}
}
.affiliate__qrcode {
	margin-right: 30px;
}
.affiliate__qrcode img {
	display: block;
	width: 100%;
	border-radius: 20px;
}
.affiliate__info h1 {
	font-size: 1.5rem;
}
.affiliate__info--group {
	margin-bottom: 0.25rem;
}
.affiliate__info--group span {
	color: rgba(255, 255, 255, 0.6);
	display: block;
}
.affiliate__info--group span i {
	cursor: pointer;
	font-size: 0.7rem;
	margin-left: 0.4rem;
	position: relative;
}
.affiliate__info--group span i:after {
	content: '';
	position: absolute;
	top: -8px;
	left: -8px;
	right: -8px;
	bottom: -8px;
	z-index: 0;
	border-radius: 100%;
	background: rgba(255, 255, 255, 0);
	transition: 250ms all ease-out;
}
.affiliate__info--group span i:hover:after {
	background: rgba(255, 255, 255, 0.25);
}
.affiliate__info--group strong {
	line-height: 1;
	font-size: 1.5rem;
}
.affiliate__info--group a {
	color: #ffc800;
}
.affiliate__info--group a:hover {
	opacity: 0.75;
}

.promo {
	-webkit-box-flex: 0;
	max-width: 33.3333333333%;
	flex: 0 0 33.3333333333%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
}
.promo__img {
	width: 100%;
}
.promo__img img {
	display: block;
	width: 100%;
	border-radius: 20px;
}
.promo__info {
	display: flex;
	padding: 24px 0;
}
.promo__info--text h1 {
	font-size: 28px;
}
.promo__info--text p {
	font-size: 0.9rem;
	opacity: 0.75;
}
.promo__info .btn {
	margin-left: auto;
	font-size: 28px;
}
@media screen and (max-width: 1200px) {
	.promo__info {
		display: block;
	}
	.promo__info .btn {
		display: block;
		margin-top: 12px;
	}
}
.promo--page {
	margin-bottom: 32px;
}
.promo--page .promo__info {
	flex-wrap: wrap;
}
.promo--page .promo__info--text {
	margin-bottom: 16px;
}
.promo--page .radio-group {
	width: calc(60% - 24px);
	box-sizing: border-box;
}
.promo--page .btn {
	width: 40%;
	box-sizing: border-box;
}
@media screen and (max-width: 1200px) {
	.promo--page .radio-group {
		width: 100%;
	}
	.promo--page .btn {
		width: 100%;
	}
}
@media screen and (max-width: 992px) {
	.promo--page {
		-webkit-box-flex: 0;
		max-width: 50%;
		flex: 0 0 50%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media screen and (max-width: 576px) {
	.promo--page {
		-webkit-box-flex: 0;
		max-width: 100%;
		flex: 0 0 100%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
	}
	.promo--page .promo__info {
		display: flex;
	}
	.promo--page .radio-group {
		width: calc(67% - 24px);
		font-size: 22px;
		padding-top: 20px;
	}
	.promo--page .radio-group .checkmark {
		top: 20px;
	}
	.promo--page .btn {
		width: 33%;
		font-size: 22px;
		margin-top: 0;
		padding-top: 20px;
	}
}

.play-mobile {
	-webkit-box-flex: 0;
	max-width: 100%;
	flex: 0 0 100%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 32px;
}
.play-mobile .btn {
	max-width: 100%;
	display: block;
}

.menu-mobile {
	-webkit-box-flex: 0;
	max-width: 25%;
	flex: 0 0 25%;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	margin-bottom: 24px;
	display: block;
}
.menu-mobile__icon {
	width: 100%;
	background-color: #0f0f0f;
	position: relative;
	padding-top: 100%;
	border-radius: 20px;
}
.menu-mobile__icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #ffc800;
	font-size: 48px;
}
.menu-mobile span {
	display: block;
	font-size: 1rem;
	margin-top: 8px;
}
@media screen and (max-width: 576px) {
	.menu-mobile__icon i {
		font-size: 24px;
	}
	.menu-mobile span {
		font-size: 0.75rem;
	}
}
